import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Layout } from '../components/layout/layout';
import { PageHeading } from '../components/layout/page-heading';
import { graphql } from 'gatsby';
import { PageDescription } from '../components/layout/page-description';

const SignUp = ({ data: { contentfulJoinLbygPage: pageData } }) => {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [gender, setGender] = useState('Female');
  const [currentStatus, setCurrentStatus] = useState('High School');
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(undefined);
    setSuccess(undefined);
    setLoading(true);
    try {
      const result = await addToMailchimp(email, {
        FNAME: fname,
        LNAME: lname,
        GENDER: gender,
        STATUS: currentStatus,
      });
      if (result.result === 'error') {
        setError(result.msg);
      } else {
        setSuccess(result.msg);
        resetForm();
      }
    } catch (err) {
      console.error(err);
      setError(err.msg);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setFname('');
    setLname('');
    setGender('Female');
    setCurrentStatus('High School');
  };

  return (
    <>
      <Layout title={pageData.title}>
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
            <div className="max-w-2xl mx-auto py-8">
              <PageHeading title={pageData.title} />
              <div id="mc_embed_signup">
                <form
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  onSubmit={handleSubmit}
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="my-8">
                      <PageDescription
                        description={pageData.description.description}
                      />
                    </div>
                    <div>
                      <img
                        className="rounded-lg"
                        src={pageData.coverPhoto.fixed.src}
                        alt={'join lbyg membership'}
                      />
                    </div>
                    <div className="indicates-required text-right my-4">
                      <span className="asterisk">*</span> indicates required
                    </div>
                    <div className="mc-field-group my-4">
                      <label
                        htmlFor="mce-EMAIL"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Email Address <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        value={email}
                        name="EMAIL"
                        className="required email shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                        id="mce-EMAIL"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mc-field-group my-4">
                      <label
                        htmlFor="mce-FNAME"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        First Name <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        value={fname}
                        name="FNAME"
                        className="required shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                        id="mce-FNAME"
                        onChange={(e) => setFname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mc-field-group my-4">
                      <label
                        htmlFor="mce-LNAME"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Last Name <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        value={lname}
                        name="LNAME"
                        className="required shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                        id="mce-LNAME"
                        onChange={(e) => setLname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mc-field-group my-4">
                      <label
                        htmlFor="mce-GENDER"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Gender <span className="asterisk">*</span>
                      </label>
                      <select
                        name="GENDER"
                        className="required shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                        id="mce-GENDER"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Prefer not to say">
                          Prefer not to say
                        </option>
                      </select>
                    </div>
                    <div className="mc-field-group my-4">
                      <label
                        htmlFor="mce-STATUS"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Are you currently studying or working?
                        <span className="asterisk">*</span>
                      </label>
                      <select
                        name="STATUS"
                        className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                        id="mce-STATUS"
                        value={currentStatus}
                        onChange={(e) => setCurrentStatus(e.target.value)}
                      >
                        <option value="High School">High School</option>
                        <option value="University">University</option>
                        <option value="Working">Working</option>
                        <option value="Studying and Working">
                          Studying and Working
                        </option>
                      </select>
                    </div>
                    <div hidden={true}>
                      <input
                        type="hidden"
                        name="tags"
                        value="3213457"
                        onChange={console.log}
                      />
                    </div>
                    <div id="mce-responses" className="clear foot my-4">
                      {error && (
                        <div
                          className="response text-red-600 font-bold"
                          id="mce-error-response"
                        >
                          {error}
                        </div>
                      )}

                      {success && (
                        <div
                          className="response font-bold text-green-600"
                          id="mce-success-response"
                        >
                          {success}
                        </div>
                      )}
                    </div>
                    <div
                      style={{ position: 'absolute', left: '-5000px' }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_d4467b5cb5228b13721d3d752_aad1eebbd4"
                        tabIndex="-1"
                        value=""
                        onChange={console.log}
                      />
                    </div>
                    <div className="clear foot flex flex-col md:flex-row gap-2 justify-between my-8">
                      <button
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="flex items-center justify-center px-8 py-3 sm:p-4 border-2 border-transparent text-base font-semibold rounded-md bg-primary hover:bg-secondary shadow-md"
                      >
                        {loading ? (
                          <>
                            <svg
                              role="status"
                              className="inline w-4 h-4 mr-3 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#111"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#ffa948"
                              />
                            </svg>
                            Joining...
                          </>
                        ) : (
                          <>Join LBYG</>
                        )}
                      </button>

                      <p className="brandingLogo">
                        <a
                          href="http://eepurl.com/h1TpG1"
                          title="Mailchimp - email marketing made easy and fun"
                        >
                          <img
                            src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
                            alt="Mailchimp logo"
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SignUp;

export const query = graphql`
  query {
    contentfulJoinLbygPage {
      title
      description {
        description
      }
      coverPhoto {
        fixed(width: 700) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
